export default function RatingBadge({ rating }) {
   return (
      <div style={{ border: '1px solid #E0E0E0', margin: 1 }}>
         <div style={{ backgroundColor: '#2F80ED', border: '3px solid white',  margin: 5, padding: 10, color: 'white', textAlign: 'center', fontFamily: 'Quantico', }}>
            {/* <p style={{ color: '#F2F2F2', fontFamily: 'Quantico', margin: 0, padding: 0, fontSize: '26px'}}>tincan</p> */}
            <h2 style={{ fontSize: '2em', fontWeight: 'bolder'}}>{rating}</h2>
            <p style={{ textTransform: 'uppercase', fontWeight: 700, fontSize: 17 }}>overall</p>
         </div>
      </div>
   )
}