// import photo from "../assets/profile-img.png";
import React from "react";
import { getReviewsByUser } from "../api/client";
import { useWalletContext } from "../api/wallet";
import "../assets/themes.css";
import ReviewItem from "../components/reviews/ReviewItem";
import Loader from "../components/search/Loader";
import NoResults from "../components/search/NoResults";
import { useWindowDimensions } from "../hooks/hooks";
import { useParams } from "react-router-dom";

export default function ProfileScreen() {
  return (
    <div className="profile-container">
      {/*<ProfileView />*/}
      <ReviewsTimeline />
    </div>
  );
}

// function ProfileView() {
//   const { address } = useWalletContext();
//   const params = useParams();

//   // right now userId is the wallet address,
//   // but we can change this to the mongoId for the user object.
//   // todo(keo): find out how to get mongoIdForUserDataObject
//   const { userId } = params;

//   return (
//     <div className="collection-profile-container-details">
//       <p>Profile ID: {userId ?? address}</p>
//     </div>
//   );
// }

function ReviewsTimeline() {
  const { address } = useWalletContext();
  const { width } = useWindowDimensions();
  const [reviews, setReviews] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const params = useParams();
  const { userId } = params;

  React.useEffect(() => {
    setLoading(true);
    async function fetchData() {
      if (userId) {
        const reviewItems = await getReviewsByUser(userId ?? address);
        setReviews(reviewItems?.reverse());
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  let items;

  if (!reviews || reviews.length === 0) {
    return (
      <div
        className="inconsolata-font"
        style={{
          backgroundColor: "#F8F8F8",
          width: "100%",
          height: "100%",
          margin: "-30px auto 0",
          textAlign: "center",
        }}
      >
        <ul
          style={{
            width: "90%",
            margin: "0 auto",
            padding: "100px 75px 100px 75px",
          }}
        >
          <h1 style={{ fontSize: 28 }}>Reviews</h1>
          <NoResults
            title="None found"
            description="Don't worry, write a review when you're ready!"
          />
        </ul>
      </div>
    );
  }

  items = reviews.map((item, index) => {
    return (
      <ReviewItem
        key={item._id}
        index={index}
        item={item}
      />
    );
  });

  return loading ? (
    <Loader />
  ) : (
    <div
      style={{
        backgroundColor: "#F8F8F8",
        width: "100%",
        height: "100%",
        margin: "-30px auto 0",
      }}
    >
      <ul
        style={{
          width: "90%",
          margin: "0 auto",
          padding: width > 700 ? "100px 75px 100px 75px" : "10px 5px 10px 5px",
        }}
      >
        <h1 className="inconsolata-font" style={{ fontSize: 28 }}>
          {items.length} Reviews
        </h1>
        {items}
      </ul>
    </div>
  );
}
