import React from "react";
import "../../assets/themes.css";

// todo: we should customize this to match the figma designs
const SearchItem = ({ label, value, id }) => {
  return (
    <div style={{ display: "flex" }}>
      <div>{label}</div>
      <div style={{ marginLeft: "10px", color: "#ccc" }}>{value}</div>
    </div>
  );
};

export default SearchItem;
