import React from "react";
import NoResults from "../search/NoResults";
import Loader from "../search/Loader";
import { CollectionItem } from "./CollectionItem";
import { useWindowDimensions } from "../../hooks/hooks";

const toItemComponent = (collection, index) => (
  <CollectionItem key={index} collection={collection} />
);

const CollectionsListView = (props) => {
  const { collections, loading } = props;
  const { width, mobileWidthBreakpoint } = useWindowDimensions();
  let items;
  let noResults;

  if (collections && collections.length !== 0) {
    items = collections.map(toItemComponent);
  } else {
    noResults = (
      <div>
        <NoResults
          title="No Collections"
          description="Please try again later"
        />
      </div>
    );
  }

  return loading ? (
    <Loader />
  ) : (
    <div className="">
      <ul style={{ display: 'flex', flexWrap: 'wrap', justifyContent: (width < mobileWidthBreakpoint) ? 'center' : 'flex-start'}} className="collection-list-container">{items}</ul>
      {noResults}
    </div>
  );
};

export default CollectionsListView;