import './reviews.css'
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { getReviewsByCollection } from "../../api/client";
import Loader from "../search/Loader";
import NoResults from "../search/NoResults";
import ReviewItem from "./ReviewItem";
import WriteAReviewButton from "./WriteReviewButton";
import { useWindowDimensions } from "../../hooks/hooks";
// import '../../assets/themes.css'

const ReviewsListView = (props) => {
  const { width } = useWindowDimensions();
  const params = useParams();
  const [reviews, setReviews] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const id = props.collection?._id || props.collection?.collection?._id || params.collectionId;
      const reviewItems = await getReviewsByCollection(id);
      setReviews(reviewItems);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line
  }, [props.collection]);


  const reviewButton = useMemo(() => (
    <WriteAReviewButton collection={props.collection} />
  ), [props.collection]);

  if (!reviews || reviews.length === 0) {
    return (
      <div className="inconsolata-font" style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%', margin: '-30px auto 0', textAlign: 'center' }}>
            <ul style={{ width: '90%', margin: '0 auto', padding: '100px 75px 100px 75px' }}>
               <h1 style={{ fontSize: 28 }}>Reviews</h1>
               <NoResults
                  title="None found"
                  description="first?"
               />
            </ul>
         </div>
    );
  }

  const items = reviews.map((item, index) => {
    return (
      <ReviewItem
            key={item._id}
            index={index}
            item={item}
         />
    );
  });

  return loading ? (
    <Loader />
  ) : (
    <div style={{ backgroundColor: '#F8F8F8', width: '100%', height: '100%', margin: '-30px auto 0', }}>
         <ul style={{ width: '100%', margin: '0 auto', padding: (width > 700) ? '100px 75px 100px 75px' : '10px 5px 10px 5px' }}>
            <h1 className="inconsolata-font" style={{ fontSize: 28 }}>{items.length} Reviews</h1>
            {items}
         </ul>
         <div style={{ display: 'flex', justifyContent: 'center', margin: '10px auto 50px', paddingBottom: '100px', }}>
            {reviewButton}
         </div>
      </div>
  );
};


export default ReviewsListView;