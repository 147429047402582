import { useParams } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import { useSearchContext } from "../contexts/SearchContext";
import { useWindowDimensions } from "../hooks/hooks";
import ReviewsListView from "../components/reviews/ReviewsListView";
import WriteAReviewButton from "../components/reviews/WriteReviewButton";
import RatingBadge from "../components/collections/RatingBadge";
import '../assets/themes.css';

export default function Collection() {
  const params = useParams();
  const { data, fetchData, clearData, isFetchingNewCollection} = useSearchContext();
  const { width } = useWindowDimensions();
   const prevCollectionIdRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    clearData();
  }, [clearData]);

  useEffect(() => {
    const fetchCollection = async () => {
      if (params.collectionId !== prevCollectionIdRef.current) {
        fetchData(`collections/${params.collectionId}`);
        prevCollectionIdRef.current = params.collectionId;
      }
    };
    fetchCollection();
  }, [params, fetchData]);

  if (!data || data.length === 0  || isFetchingNewCollection) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {width > 700 ? (
        <DesktopCollectionScreen collections={data} />
      ) : (
        <MobileCollectionScreen collections={data} />
      )}
    </>
  );
}

const DesktopCollectionScreen = ({ collections }) => {
  const stats = collections?.[0];
  const reviews = collections?.[1];
  const collection = collections?.[2];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <main style={{ padding: "2rem" }}>
      <div
        className="project-details-section"
        style={{ display: "flex", flexDirection: "row", margin: "0 auto" }}
      >
        <img
          style={{ height: "400px", margin: "0 20px", maxWidth: "50%" }}
          src={collection?.image || collection?.banner_image}
          alt={collection?.name}
        />
        <div
          className="project-statistics-box"
          style={{
            margin: "0 20px",
            height: "100%",
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <div style={{ marginRight: 10 }}>
              <RatingBadge
                rating={stats?.totalScore ? stats?.totalScore.toFixed(1) : "N/A"}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontFamily: "Inconsolata", fontSize: 40 }}>
                {collection?.name}
              </p>
              <a
                href={`https://opensea.io/collection/${collection?.os_slug}`}
              >
                <p
                  style={{
                    fontFamily: "Quantico",
                    fontSize: 12,
                    textDecoration: "underline",
                  }}
                >
                  View on OpenSea
                </p>
              </a>
            </div>
          </div>
          <div style={{ display: "flex", maxWidth: 450 }}>
            <div
              style={{
                padding: 10,
                border: "1px solid #E0E0E0",
                textAlign: "center",
                minWidth: 100,
              }}
            >
              <p
                style={{
                  fontFamily: "Inconsolata",
                  fontSize: "0.7em",
                  textTransform: "capitalize",
                }}
              >
                degn reviews
              </p>
              <p
                style={{
                  fontFamily: "Quantico",
                  fontSize: "2em",
                  fontWeight: "bolder",
                }}
              >
                {stats?.totalDegnReviews || 0}
              </p>
            </div>
            <div
              style={{
                padding: 10,
                border: "1px solid #E0E0E0",
                textAlign: "center",
                minWidth: 100,
              }}
            >
              <p
                style={{
                  fontFamily: "Inconsolata",
                  fontSize: "0.7em",
                  textTransform: "capitalize",
                }}
              >
                hodlr reviews
              </p>
              <p
                style={{
                  fontFamily: "Quantico",
                  fontSize: "2em",
                  fontWeight: "bolder",
                }}
              >
                {stats?.totalHodlrReviews || 0}
              </p>
            </div>
          </div>
          <div style={{ maxWidth: 500 }}>
            <BarChart title={"utility"} rating={stats?.totalUtilityScore} />
            <BarChart
              title={"community"}
              rating={stats?.totalCommunityScore}
            />
            <BarChart
              title={"leadership"}
              rating={stats?.totalLeadershipScore}
            />
          </div>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <p style={{ fontSize: "0.8em" }}>
              Date Added: {formatDate(collection?.addedDate)}
            </p>
            <p style={{ fontSize: "0.9em" }}>{collection?.description}</p>
          </div>
          <WriteAReviewButton collection={collection} />
        </div>
      </div>
      <ReviewsListView collection={reviews} />
    </main>
  );
};

const MobileCollectionScreen = ({ collections }) => {
  const stats = collections?.[0];
  const reviews = collections?.[1];
  const collection = collections?.[2];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  return (
    <div>
      <div className="mobile-collection-header">
        <div
          style={{
            position: "relative",
            padding: "0 15px 10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            borderBottom: "1px solid black",
            boxShadow: "0px 4px 0px 3px rgba(51,51,51,0.1)",
          }}
        >
          <p style={{ fontFamily: "Quantico", fontSize: 20 }}>
            {collection?.name}
          </p>
          <a href={`https://opensea.io/collection/${collection?.os_slug}`}>
            <p
              style={{
                fontFamily: "Quantico",
                fontSize: 12,
                textDecoration: "underline",
              }}
            >
              OpenSea
            </p>
          </a>
        </div>
        <img
          style={{ objectFit: "cover", height: "200px", width: "100%" }}
          src={collection?.image}
          alt={collection?.name}
        />
      </div>
      <div
        style={{
          width: "100%",
          minHeight: "600px",
          backgroundColor: "white",
          borderBottom: "1px solid #BDBDBD",
        }}
      >
        <div
          className="mobile-collection-stats"
          style={{
            position: "relative",
            width: "85%",
            margin: "-60px auto 0",
            height: "auto",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "0 25px",
          }}
        >
          <div
            className="stats-row"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ padding: 10, textAlign: "left" }}>
              <p
                style={{
                  fontFamily: "Inconsolata",
                  fontSize: "0.7em",
                  textTransform: "capitalize",
                }}
              >
                degn reviews
              </p>
              <p
                style={{
                  fontFamily: "Quantico",
                  fontSize: "2em",
                  fontWeight: "bolder",
                }}
              >
                {stats?.totalDegnReviews || 0}
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  fontFamily: "Inconsolata",
                  fontSize: "0.7em",
                  textTransform: "capitalize",
                }}
              >
                overall rating
              </p>
              <p
                style={{
                  fontFamily: "Quantico",
                  fontSize: "2em",
                  fontWeight: "bolder",
                }}
              >
                {stats?.totalScore ? stats?.totalScore / collection?.totalReviews : "-"}
              </p>
            </div>
            <div style={{ padding: 10, textAlign: "right" }}>
              <p
                style={{
                  fontFamily: "Inconsolata",
                  fontSize: "0.7em",
                  textTransform: "capitalize",
                }}
              >
                hodlr reviews
              </p>
              <p
                style={{
                  fontFamily: "Quantico",
                  fontSize: "2em",
                  fontWeight: "bolder",
                }}
              >
                {stats?.totalHodlrReviews || 0}
              </p>
            </div>
          </div>
          <div
            className="stats-row"
            style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}
          >
            <div>
              <BarChart
                title={"utility"}
                rating={
                  stats?.totalScore && stats?.totalUtilityScore
                    ? stats?.totalUtilityScore / collection?.totalReviews
                    : 0
                }
              />
              <BarChart
                title={"community"}
                rating={
                  stats?.totalScore && stats?.totalCommunityScore
                    ? stats?.totalCommunityScore / collection?.totalReviews
                    : 0
                }
              />
              <BarChart
                title={"leadership"}
                rating={
                  stats?.totalScore && stats?.totalLeadershipScore
                    ? stats?.totalLeadershipScore / collection?.totalReviews
                    : 0
                }
              />
            </div>
          </div>
          <div style={{ marginTop: 40 }}>
            <p style={{ fontSize: "0.9em" }}>
              Date Added: {formatDate(collection?.addedDate)}
            </p>
            <p style={{ fontSize: "0.9em" }}>{collection?.description}</p>
          </div>
        </div>
      </div>
      <div
        className="mobile-collection-reviews"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F8F8F8",
          width: "100%",
          borderBottom: "1px solid #BDBDBD",
          padding: "0 0.8rem",
        }}
      >
        <div style={{ position: "relative", margin: "-20px auto 40px" }}>
          <WriteAReviewButton collection={collection} />
        </div>
        <ReviewsListView collection={reviews} />
      </div>
    </div>
  );
};

const PointDiv = ({ filled, title, index }) => {
  const { width } = useWindowDimensions();

  const cellWidth = width < 700 ? width / 5 - 10 : 90;
  const cellHeight = 30;

  const pointContainerStyle = {
    backgroundColor: filled ? "#2F80ED" : "#e0e0e0",
    height: cellHeight,
    width: cellWidth,
    color: "white",
    display: "flex",
    alignItems: "center",
    fontFamily: "Inconsolata",
    fontSize: width < 700 ? "0.9em" : "1em",
  };

  return (
    <div style={pointContainerStyle}>
      <p style={{ zIndex: index === 1 ? 10 : 1, textTransform: "capitalize", marginLeft: index === 1 ? 10 : 0 }}>
        {index === 1 ? `${title}` : null}
      </p>
    </div>
  );
};

const BarChart = ({ title, rating }) => {
  const arr = [{ key: 1 }, { key: 2 }, { key: 3 }, { key: 4 }, { key: 5 }];

  return (
    <div style={{ display: "flex", marginTop: 4, marginBottom: 4 }}>
      {arr.map((item) => (
        <PointDiv key={item.key} index={item.key} filled={Math.round(rating) >= item.key} title={title} />
      ))}
    </div>
  );
};
