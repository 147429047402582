import React, { createContext, useState, useCallback } from "react";
import logo from "../assets/logo.svg";
import axios from "axios";

export const SearchContext = createContext();

export const useSearchContext = () => {
  const context = React.useContext(SearchContext);
  return context;
};

const SearchContextProvider = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFetchingNewCollection, setIsFetchingNewCollection] = useState(false);

  const clearData = useCallback(() => {
    setData([]);
  }, []);

  const fetchData = useCallback((query) => {
    const endpoint = query;
    if (!loading) {
      setLoading(true);
      setIsFetchingNewCollection(true);
      //console.trace('fetchData called from:', endpoint);
      axios
        .get(`${process.env.REACT_APP_API_HOST}api/${endpoint}`)
        .then((response) => {
          const items = response?.data;
          const itemsArray = Object.values(items);
          setData(
            itemsArray.map((item) => {
              return (
                {
                   ...item,
                   image: item?.image || item?.collection?.image || logo, // default collection image
                   value: item?.name || item?.collection?.name,
                   label: item?.name || item?.collection?.name,
                   totalScore: item?.avgRating || item?.collectionOverallRating?.avgRating,
                   totalCommunityScore: item?.avgCommunityRating || item?.collectionOverallRating?.avgCommunityRating,
                   totalLeadershipScore: item?.avgLeadershipRating || item?.collectionOverallRating?.avgLeadershipRating,
                   totalUtilityScore: item?.avgUtilityRating || item?.collectionOverallRating?.avgUtilityRating,
                   totalHodlrReviews: item?.avgHodlrVotes || item?.collectionOverallRating?.avgHodlrVotes,
                   totalDegnReviews: item?.avgDegenVotes || item?.collectionOverallRating?.avgDegenVotes,
                 }
              )
            })
          );
          setLoading(false);
          setIsFetchingNewCollection(false);
        })
        .catch((error) => {
          console.log(
            "Encountered an error with fetching and parsing data",
            error
          );
          setIsFetchingNewCollection(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <SearchContext.Provider value={{ data, loading, fetchData, clearData, isFetchingNewCollection }}>
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
