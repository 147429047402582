import { useState, useEffect } from 'react';

const getWindowDimensions = () => {
   const { innerWidth: width, innerHeight: height } = window;
   const mobileWidthBreakpoint = 650;
   return {
      width,
      height,
      mobileWidthBreakpoint
   };
}

export const useWindowDimensions = () => {
   const [ windowDimensions, setWindowDimensions ] = useState(getWindowDimensions());

   useEffect(() => {
      const handleResize = () => {
         setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
   }, [ ])
   return windowDimensions;
}