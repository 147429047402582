import "../../assets/themes.css";
import React from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import SearchItem from "./SearchItem";

const SearchContainer = ({ searchTerm, collections }) => {
  const navigate = useNavigate();

  return (
    <div className="search-container">
      <Select
        options={collections}
        className="search-form"
        name="search"
        placeholder="SEARCH PROJECT_ _"
        isSearchable={true}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => "No Collections Found"}
        openMenuOnClick={false}
        openMenuOnFocus={false}
        formatOptionLabel={SearchItem}
        onChange={(newValue, actionMeta) => {
          navigate(`/collections/${newValue.collection._id}`)
        }}
      />
    </div>
  );
};

export default SearchContainer;
