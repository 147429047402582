import "@rainbow-me/rainbowkit/styles.css";
import './polyfills';

import { connectorsForWallets, RainbowKitProvider, lightTheme } from "@rainbow-me/rainbowkit";
import { injectedWallet, rainbowWallet, metaMaskWallet, coinbaseWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig, useAccount, WagmiConfig } from "wagmi";
import { mainnet, polygon, optimism, arbitrum } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, polygon, optimism, arbitrum],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY }), // see alchemy.com
    publicProvider(), // not for production, will face rate-limiting on the public provider endpoints
  ]
);

const projectId = process.env.REACT_APP_WALLETCONNECT;

const connectors = connectorsForWallets([
  {
    groupName: 'Most Common',
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      metaMaskWallet({ projectId, chains }),
      coinbaseWallet({ chains, appName: 'Tin Can' }),
      walletConnectWallet({ projectId, chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient
});

export function WagmiClient(props) {
  const isMobile = false; // todo...
  const modalSize = isMobile ? "compact" : "wide";
  const customTheme = lightTheme({
    accentColor: "white",
    accentColorForeground: "white",
    fontStack: "system",
    overlayBlur: "small",
  });
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        modalSize={modalSize}
        theme={customTheme}
        coolMode
      >
        {props.children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

// use this for grabbing Wallet metadata for reviews, etc.
export function useWalletContext() {
  const { address, isConnected } = useAccount();
  if (!isConnected) {
    return {};
  }

  return {
    isConnected,
    address,
    // add extra wallet metadata
    // ENS name, chain, avatar, etc..
  };
}