import React from "react";
import { useWindowDimensions } from "../../hooks/hooks";
import './reviews.css'

function ReviewFormModalContent({ handleReviewFormSubmit, collection, reviewState, dispatch }) {
   const { width, mobileWidthBreakpoint } = useWindowDimensions();
   const { isHolder, description, overallRating, communityRating, utilityRating, leadershipRating } = reviewState;

   const handleDescriptionChange = React.useCallback((e) => {
      dispatch({ type: 'updateDescription', text: e.target.value })
   }, [ dispatch ]);

   const ownershipStatus = isHolder ? 'Hodlr' : 'Degen';

   return (
      <div className="quantico-font" style={{ height: 400, padding: '40px 55px', textAlign: 'center',  }}>
         
         <p style={{ fontSize: 16, marginBottom: 10 }}>Rate the overall project quality of <span style={{ fontWeight: 'bolder' }}>{collection.name}</span></p>

         {/* Degen vs Hodlr status */}
         <p style={{ fontSize: 14, marginBottom: 10 }}>You are a: <span style={{ fontWeight: 'bolder' }}>{ownershipStatus}</span></p>
         <form className="review-form" style={{ paddingBottom: 50 }} onSubmit={handleReviewFormSubmit}>
            <StarRating rating={overallRating} dispatch={dispatch} type={'updateOverallRating'} />

            <p style={{ fontSize: 14, marginTop: 15 }}>Write a review (optional)</p>
            <textarea
               placeholder="Tell us more"
               value={description}
               style={{ fontSize: 14, borderWidth: '2px', borderColor: 'rgb(178, 178, 178)', marginTop: 10, marginBottom: 10, padding: 5, height: 120, width: '100%' }}
               onChange={handleDescriptionChange}
            />

            <div style={{ marginTop: 10, marginBottom: 13 }}>
               <p>Rate the other qualities of <span style={{ fontWeight: 'bolder' }}>{collection.name}</span></p>
               <p>(Optional)</p>
            </div>
            <div style={{ marginBottom: 8, display: 'flex', flexDirection: (width < mobileWidthBreakpoint) ? 'column' : 'row', alignItems: (width < mobileWidthBreakpoint) ? 'center' : 'baseline', justifyContent: 'center' }}>
            <h1 style={{ fontSize: (width < mobileWidthBreakpoint) ? 17 : 20, fontWeight: 400, margin: '0 10px' }}>Utility</h1>
               <StarRating rating={utilityRating} dispatch={dispatch} type={'updateUtilityRating'} />
            </div>

            <div style={{ marginBottom: 8, display: 'flex', flexDirection: (width < mobileWidthBreakpoint) ? 'column' : 'row', alignItems: (width < mobileWidthBreakpoint) ? 'center' : 'baseline', justifyContent: 'center' }}>
            <h1 style={{ fontSize: (width < mobileWidthBreakpoint) ? 17 : 20, fontWeight: 400, margin: '0 10px' }}>Community</h1>
               <StarRating rating={communityRating} dispatch={dispatch} type={'updateCommunityRating'}  />
            </div>

            <div style={{ marginBottom: 8, display: 'flex', flexDirection: (width < mobileWidthBreakpoint) ? 'column' : 'row', alignItems: (width < mobileWidthBreakpoint) ? 'center' : 'baseline', justifyContent: 'center' }}>
               <h1 style={{ fontSize: (width < mobileWidthBreakpoint) ? 17 : 20, fontWeight: 400, margin: '0 10px' }}>Leadership</h1>
               <StarRating rating={leadershipRating} dispatch={dispatch} type={'updateLeadershipRating'}  />
            </div>

            <input style={{ marginTop: 10, marginBottom: 10 }} className="submit-review-button" type="submit" value="Review" />
         </form>
      </div>
   )
}

 const StarRating = ({ rating, dispatch, type }) => {
   const { width, mobileWidthBreakpoint } = useWindowDimensions();
   const [hover, setHover] = React.useState(0);
   return (
     <div className="star-rating" style={{ marginBottom: 10}}>
       {[...Array(5)].map((star, index) => {
         index += 1;
         return (
           <button
             type="button"
             key={index}
             className={index <= (hover || rating) ? "on" : "off"}
             onClick={() => {
               dispatch({ type: type, rating: index})
             }}
             onMouseEnter={() => setHover(index)}
             onMouseLeave={() => setHover(rating)}
           >
             <span className="star" style={{ fontSize: (width < mobileWidthBreakpoint) ? 23 : 32 }}>&#9733;</span>
           </button>
         );
       })}
     </div>
   );
 };

export { ReviewFormModalContent };