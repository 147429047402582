import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import home_image from "../assets/home-image.png";
import home_ratings from "../assets/home-ratings.png";
import CollectionsListView from "../components/collections/CollectionListView";
import SearchContainer from "../components/search/SearchContainer";
import { useSearchContext } from "../contexts/SearchContext";
import { useWindowDimensions } from "../hooks/hooks";

export default function Home() {
  const { data: collections, loading, clearData, fetchData } = useSearchContext();
  const location = useLocation();

  useEffect(() => {
    clearData();
  }, [clearData]);

  useEffect(() => {
    if (!loading && collections.length === 0) {
      fetchData('collections');
    }
  });

  return (
    <main className="home">
      <HeroSpace collections={collections} loading={loading} />
      <CollectionWrapper collections={collections} loading={loading} />
    </main>
  );
}

function CollectionWrapper({ collections, loading }) {
  const { width, mobileWidthBreakpoint } = useWindowDimensions();

  const [activeToggle, setActiveToggle] = useState("recent-collections");
  // eslint-disable-next-line
  const [sortMethod, setSortMethod] = useState("price-low-to-high");

  const onToggleClick = (event) => {
    setActiveToggle(event.target.value);
  };

  const onSortChange = (event) => {
    setSortMethod(event.target.value);
  };

  const toggleButtonClass = (toggle) =>
    `toggle-button ${activeToggle === toggle ? "active-button" : ""}`;

  return (
    <section>
      <nav className="filter-nav-bar">
        {/*<div
          className="toggle-button-container"
          style={{ fontSize: width < mobileWidthBreakpoint ? 12.5 : "" }}
        >
          <button
            name="recent-collections-button"
            value="recent-collections"
            onClick={onToggleClick}
            className={toggleButtonClass("recent-collections")}
          >
            Recent
          </button>
          <button
            name="24-hr-collections-button"
            value="24-hr-collections"
            onClick={onToggleClick}
            className={toggleButtonClass("24-hr-collections")}
          >
            24 hour
          </button>
        </div>
        <div style={{ fontSize: width < mobileWidthBreakpoint ? 12.5 : "" }}>
          <label hidden className="sort-toggle">
            Sort by Price Low to High
          </label>
          <select
            className="sort-toggle"
            name="sort-by"
            htmlFor="sort-by"
            onChange={onSortChange}
          >
            <option value="price-low-to-high">Sort by Price Low to High</option>
            <option value="price-high-to-low">Sort by Price High to Low</option>
            <option value="highest-rated">Sort by Highest Rated</option>
            <option value="most-transactions">Sort by Most Transactions</option>
          </select>
        </div>*/} 
      </nav>

      <CollectionsListView collections={collections} loading={loading} />
    </section>
  );
}

const HeroSpace = ({ collections, loading }) => {
  const { width, mobileWidthBreakpoint } = useWindowDimensions();

  //'doodles-official' Only
  const doodlesCollection = collections.find(
    (collection) => collection.collection && collection.collection.os_slug === 'doodles-official'
  );
  let doodlesScore = '';
  if (doodlesCollection) {
    doodlesScore = doodlesCollection.totalScore;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: width < mobileWidthBreakpoint ? "column" : "row",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", marginRight: 10 }}>
        <>
          <div className="rating-icon" style={{ marginRight: -65, zIndex: 3 }}>
            <div style={{ border: "1px solid #E0E0E0", margin: 1 }}>
              <div
                style={{
                  backgroundColor: "#2F80ED",
                  border: "5px solid white",
                  padding: "20px 30px",
                  color: "white",
                  textAlign: "center",
                  fontFamily: "Quantico",
                }}
              >
                <p
                  style={{
                    color: "#F2F2F2",
                    fontFamily: "Quantico",
                    margin: 0,
                    padding: 0,
                    fontSize: "14px",
                  }}
                >
                  tincan
                </p>
                <h2
                  style={{
                    fontSize: "2em",
                    fontWeight: "bolder",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {doodlesScore}
                </h2>
                <p
                  style={{
                    textTransform: "uppercase",
                    fontWeight: 700,
                    fontSize: 17,
                  }}
                >
                  overall
                </p>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <img src={home_image} alt="" />
            <img src={home_ratings} alt="" />
            <a
              style={{
                marginTop: 25,
                marginBottom: 20,
                textDecoration: "underline",
                color: "#2F80ED",
              }}
              href="/"
            >
              Wtf is this?
            </a>
          </div>
        </>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: 10,
        }}
      >
        <div>
          <p
            style={{
              fontSize: width < mobileWidthBreakpoint ? 42 : 58,
              font: "Helvetica",
              textAlign: "center",
              marginBottom: 25,
            }}
          >
            Getting rugged
            <br />
            f***ing sucks.
          </p>
        </div>
        <SearchContainer collections={collections} />
      </div>
    </div>
  );
};
