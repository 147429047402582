import holderIcon from '../../assets/holder-icon.svg';
import '../../assets/themes.css';
import { useWindowDimensions } from '../../hooks/hooks';
import { Link } from 'react-router-dom';

export default function ReviewItem({ index, item }) {
   const {
      overallRating,
      walletAddress,
      description,
      addedDate,
      isHolder,
      nftTitle,
   } = item;

   const { width } = useWindowDimensions();
   return (
      <li index={index} className="inconsolata-font" style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', border: '0.5px solid #BDBDBD', height: '300px', width: '100%', margin: '15px auto', padding: '10px', }}>
         <div style={{ display: 'flex', justifyContent: 'flex-start', height: '85%', }}>

            {/* Hexagon Score Badge */}
            <div style={{ textAlign: 'center', margin: 5 }}>
               <div style={{ border: '1px solid #E0E0E0', margin: 1 }}>
                  <div style={{ backgroundColor: '#2F80ED', border: (width < 700) ? '2px solid white' : '3px solid white', margin: 5, padding: (width < 700) ? 6 : 10, color: 'white', textAlign: 'center', fontFamily: 'Quantico', }}>
                     <h2 style={{ fontSize: (width < 700) ? '1.5em' : '2em', fontWeight: 'bolder' }}>{overallRating}</h2>
                     <p style={{ textTransform: 'uppercase', fontWeight: 700, fontSize: (width < 700) ? 13 : 17 }}>overall</p>
                  </div>
               </div>
            </div>

            {/* Review Text */}
            <div style={{ margin: 8, wordWrap: 'break-word', whiteSpace: 'normal', overflow: 'overlay', wordBreak: 'break-word' }}>
               {nftTitle ?
                  <p style={{ fontSize: (width < 700) ? 16 : 18, lineHeight: '1.8rem', textOverflow: 'ellipses', fontWeight: 'bold'}}>{nftTitle}</p>
                  :
                  null
               }

               {description ?
                  <p style={{ fontSize: (width < 700) ? 14 : 16, lineHeight: '1.8rem', textOverflow: 'ellipses', }}>
                     {description}
                  </p>
                  :
                  null
               }
            </div>

         </div>
         <div style={{ borderTop: '0.5px solid #BDBDBD', margin: '0 -0.5rem', padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '0.8em', }}>
            <div style={{ display: 'flex' }}>
               <p style={{ width: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textDecoration: 'underline', marginRight: 7 }}><Link to={`/profile/${walletAddress}`}>{walletAddress}</Link></p>
               <p style={{ marginRight: 7, color: (isHolder) ? "#2F80ED" : "#EB5757", display: "flex" }}>
                  {(isHolder) ?
                     <><img src={holderIcon} alt="holder-icon" /><span style={{ marginLeft: 3 }}>HODLR</span></>
                     :
                     <><span style={{ marginLeft: 3 }}>DEGEN</span></>
                  }
               </p>
               {addedDate ?
                  <p style={{ color: '#4F4F4F' }}>{((new Date(addedDate)).getMonth()) + 1}/{(new Date(addedDate)).getDate()}/{(new Date(addedDate)).getFullYear()}</p>
                  :
                  <p></p>
               }
            </div>
         </div>
      </li>
   )
}