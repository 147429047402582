import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { WagmiClient } from "./api/wallet";

const root = ReactDOM.createRoot(document.getElementById("root"));

// StrictMode causes effects to run twice (DEV only)
// Bypassing it fixes local bug where Wallet modal does not close.
const useStrict = false;
const strict = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

root.render(<WagmiClient>{useStrict ? strict : <App />}</WagmiClient>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
