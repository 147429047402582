import React, { useEffect, useState, useReducer } from "react";
import { Modal } from 'react-responsive-modal';
import { ReviewFormModalContent } from "./ReviewForm";
import { createReview, walletHasCollection, getReviewsByCollection } from "../../api/client";
import 'react-responsive-modal/styles.css';
import './reviews.css'
import { useWalletContext } from "../../api/wallet";

const initialReviewState = {
  description: '',
  overallRating: 0,
  communityRating: 0,
  utilityRating: 0,
  leadershipRating: 0,
  isHolder: null
}

const reviewStateReducer = (reviewState, action) => {
  switch (action.type) {
    case 'reset':
      {
        return initialReviewState
      }
    case 'updateIsHolderState':
      {
        return { ...reviewState, isHolder: action.value }
      }
    case 'updateDescription':
      {
        return { ...reviewState, description: action.text }
      }
    case 'updateOverallRating':
      {
        return { ...reviewState, overallRating: action.rating }
      }
    case 'updateCommunityRating':
      {
        return { ...reviewState, communityRating: action.rating }
      }
    case 'updateUtilityRating':
      {
        return { ...reviewState, utilityRating: action.rating }
      }
    case 'updateLeadershipRating':
      {
        return { ...reviewState, leadershipRating: action.rating }
      }
    default:
      {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
  }
}

const WriteAReviewButton = ({collection}) => {
  const [reviewState, dispatch] = useReducer(reviewStateReducer, initialReviewState);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const onCloseReviewModal = () => setOpenReviewModal(false);
  const { isConnected, address } = useWalletContext(); // get the reviewing user's wallet address
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    async function getOwnershipStatus() {
      const slug = collection?.os_slug;
      const isHodlr = await walletHasCollection(address, slug);
      //console.log(' is hodlr ', isHodlr);
      dispatch({ type: 'updateIsHolderState', value: isHodlr })
    }
    if (!hasFetched) {
      getOwnershipStatus(); // this only needs to be called once
      setHasFetched(true);
    }
  }, [collection, address, dispatch, hasFetched]);


  // TODO - consider lifting up this state to be loaded/stored via context so its not fetched twice - for this logic and to load reviews on the screen in ReviewsListView.js
  const onWriteReviewClick = async () => {
    const id = collection?._id;
    const collectionReviews = await getReviewsByCollection(id);
    if (!isConnected) {
      alert('Connect your wallet to write a review!')
    } else
    if (collectionReviews.find(review => review.walletAddress === address)) {
      alert(`You've already left a review on this collection! At this time, we only allow one review per collection.`)
    } else {
      setOpenReviewModal(true);
    }
  }

  const handleReviewFormSubmit = React.useCallback(
    async (event) => {
      // TODO -- add fallback "Sign In" modal
      if (!isConnected) {
        console.error('Connect wallet to write a review.')
        event.preventDefault();
        alert('Connect to your wallet to write a review!')
        return;
      }
      const data = {
        nftTitle: collection?.name || collection?.collection?.name,
        nftCollectionId: collection?._id || collection?.collection?._id,
        osSlug: collection?.os_slug || collection?.collection?.os_slug,
        walletAddress: address,
        ...reviewState,
      }

      if ((data.overallRating >= 1 || data.utilityRating >= 1 || data.communityRating >= 1) && data.description) {
        event.preventDefault();
        createReview(data);
        setOpenReviewModal(false);
        dispatch({ type: 'reset' });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        event.preventDefault();
      }
    },
    [
      isConnected, 
      collection?.name,
      collection?.collection?.name,
      collection?._id,
      collection?.collection?._id,
      collection?.os_slug,
      collection?.collection?.os_slug,
      address, 
      reviewState
    ]
  )

  return (
    <div>
      <button className="review-button" onClick={() => onWriteReviewClick()}>write a review</button>
      <Modal open = { openReviewModal } onClose ={ onCloseReviewModal } center classNames ={ { modal: 'modal' } } >
        <ReviewFormModalContent collection={collection} dispatch={dispatch} reviewState={reviewState} handleReviewFormSubmit={handleReviewFormSubmit} /> 
      </Modal>
    </div>
  )
}
export default WriteAReviewButton;