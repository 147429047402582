import React from "react";
import SearchContextProvider from "./contexts/SearchContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./routes/home";
import Collection from "./routes/collection";
import ProfileScreen from './routes/profile';
import NavBar from "./components/navigation/NavBar";
import Footer from "./components/navigation/Footer";

function App() {
  return (
    <BrowserRouter>
      <SearchContextProvider>
        <NavBar title="tincan" />
        <Routes>
          {/* home page */}
          <Route path="/" element={<Home />} />

          {/* collection pages */}
          <Route path="collections/:collectionId" element={<Collection />} />

          <Route path="profile" element={<ProfileScreen />} />
          <Route path="profile/:userId" element={<ProfileScreen />} />

          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p className="p-12">There's nothing here!</p>
              </main>
            }
          />
        </Routes>
        <Footer />
      </SearchContextProvider>
    </BrowserRouter>
  );
}



export default App;