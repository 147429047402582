import React from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { postWalletId } from "../../api/client";
import { useWalletContext } from "../../api/wallet";

export default function WalletConnectButton({ onWalletAddressUpdate }) {
  const isMobile = true; // todo...
  const label = isMobile ? "🔐" : "Sign In";

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // TODO: serve 'authenticationStatus' checks from auth system (Next.JS)
        // see https://www.rainbowkit.com/docs/authentication
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button onClick={openConnectModal} type="button">
                    {label}
                  </button>
                );
              }

              if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              }

              return (
                <ConnectedButton
                  onWalletAddressUpdate={onWalletAddressUpdate}
                />
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}

function ConnectedButton({ onWalletAddressUpdate }) {
  const { isConnected, address } = useWalletContext();

  React.useEffect(() => {
    if (isConnected) {
      postWalletId(address)
        .then((response) => {
          const walletAddress = response.user.wallet;
          onWalletAddressUpdate(walletAddress); 
        })
        .catch((error) => {
          console.log("Error updating wallet address:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, address]);

  return (
    <ConnectButton
      label="🔐"
      showBalance={false}
      accountStatus="avatar"
      chainStatus="none"
    />
  );
}
