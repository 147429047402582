import React from "react";
import '../../assets/themes.css';

export default function Footer(props) {
  return (
    <footer className="footer" >
      <a href="https://www.cryptopackagedgoods.com" className="link" target="_blank" rel="noreferrer">
        Powered by CPG
      </a>
    </footer>
  );
}
