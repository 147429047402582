import React from "react";
import { Link } from "react-router-dom";
import WalletConnectButton from "../auth/WalletConnectButton";
import "../../assets/themes.css";
import { useWindowDimensions } from "../../hooks/hooks";

export default function NavBar(props) {
   const { title } = props;
   const { width } = useWindowDimensions();
   const [walletAddress, setWalletAddress] = React.useState("");

   const handleWalletAddressUpdate = (address) => {
    setWalletAddress(address);
  };

   return (
      <div className="nav-wrapper">
         <nav className="navBar" >

            <div className="navBar-children">
               <div className="navIconContainer">
                  <Link to="/">
                     📦
                  </Link>
               </div>
               <h1 style={(width < 700) ? { fontSize: 21 } : { fontSize: 30 }} >
                  <Link to="/">
                     {title}
                  </Link>
               </h1>
               <div className="navIconContainer">
                  <Link to={"/profile/" + walletAddress} className="navIcon">💾</Link>
                  <WalletConnectButton 
                     style={{ background: 'none' }} 
                     className="navIcon" 
                     onWalletAddressUpdate={handleWalletAddressUpdate}
                  />
                  {/* <p className="navIcon">🌙</p>*/}
               </div>
            </div>


         </nav>
         <div style={(width < 700) ? { margin: '0 -1rem', borderBottom: "1px solid #828282" } : {}}></div>
      </div>
   );
}
