import React from "react";
import { useNavigate } from "react-router-dom";

// todo: UI needs to be updates see themes.css
export const CollectionItem = (props) => {
  const collection = props.collection?.collection || props.collection || {};
  const { name, image, _id, totalScore } = collection;
  
  const navigate = useNavigate();
  return (
     <li onClick={() => navigate(`/collections/${_id}`)} title={`${_id}`}>
        <div style={{ margin: 3, height: 230, width: 210 }}>
           <img style={{ height: "200px", width: "200px"}} src={image} alt={name} />
           <p style={{ textAlign: 'center', textTransform: 'capitalize', fontSize: 18 }} className="inconsolata-font">{name}</p>
           
           <div style={{ width: 50, marginTop: -218, marginLeft: 9, zIndex: 2, position: 'relative' }}>
               <div style={{ width: 40, height: 40, backgroundColor: '#2F80ED', border: '1px solid white', margin: 2, padding: 4, color: 'white', textAlign: 'center', fontFamily: 'Quantico', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <h2 style={{ fontWeight: 'bolder' }}>{(totalScore) ? totalScore.toFixed(1) : "N/A"}</h2>
                  <p style={{ textTransform: 'uppercase', fontWeight: 700, fontSize: 7 }}>overall</p>
               </div>

           </div>
        </div>
     </li>
  );
};