import axios from "axios";

/**
 * @returns {reviews}
 */
export async function getReviews() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}api/reviews`
    );
    if (!response || !response.data) {
      return [];
    }
    const { reviews } = response.data;
    return reviews;
  } catch (error) {
    console.log("Encountered an error with fetching and parsing data", error);
    return [];
  }
}

// TODO - change this to return an array after updates to BE
export async function getReviewsByCollection(id) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_HOST}api/reviews/collections/${id}`);
    if (!response || !response.data) return [];
    const { reviews } = response.data;
    return reviews;
  } catch (error) {
    console.log(`Encountered an error with fetching and parsing review data ${error}`);
    return [];
  }
}

export async function createReview(data) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}api/reviews`,
      data
    );
    return response.data.review._id;
  } catch (error) {
    console.log("Encountered an error with POST API", error);
    return [];
  }
}

export async function updateReview(id, data) {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_HOST}api/reviews/${id}`, data)
    console.log(JSON.stringify(response.data));
  } catch (error) {
    console.log(`Encountered an error with POST API ${error}`);
    return [];
  }
}

// given a user's wallet ID, returns all the reviews they've written (in order of time)
export async function getReviewsByUser(id) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_HOST}api/reviews/wallet/${id}`)
    if (!response || !response.data) {
      return [];
    }
    const { reviews } = response.data;
    return reviews;
  } catch (error) {
    console.log(`Encountered an error with POST API ${error}`);
    return [];
  }
}

/**
 * @returns {collections}
 */
export async function getCollections() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}api/collections`
    );
    if (!response || !response.data) {
      return [];
    }
    // TODO - change this to return an array after updates to BE
    const items = response.data;
    const collections = items.map((item) => {
    return ({ ...item,
          image: item?.image, // default collection image
          value: item?.name,
          label: item?.name,
          totalScore: item?.totalScore,
          totalCommunityScore: item?.totalCommunityScore,
          totalLeadershipScore: item?.totalLeadershipScore,
          totalUtilityScore: item?.totalUtilityScore,
        })
    })
    return collections;
  } catch (error) {
    console.log("Encountered an error with fetching and parsing data", error);
    return [];
  }
}

// when a user connects their wallet, 
// POST the address to the server
export async function postWalletId(id) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_HOST}api/user/wallet/${id}`);
    return response.data; // todo...
  } catch (error) {
    console.log("Encountered an error with POST API", error);
    return [];
  }
}

/**
 * Determines "degen" vs "hodlr" by checking if a wallet has an asset from the given collection 
 * 
 * @param {string} walletId - The current user's wallet address
 * @param {string} slug - The OpenSea collection "slug"
 * @returns {boolean}
 */
export async function walletHasCollection(walletId, slug) {
  let cursor = '';
  if (walletId) {
    while (true) {
      const data = await getOSAssetsForWallet(walletId, cursor);
      for (const asset of data.data[0].nfts) {
        if (asset.collection.slug === slug) {
          return true;
        }
      }
      cursor = data.next;
      if (!cursor) {
        break;
      }
    }
    return false;
  } else {
    return false;
  }
}

async function getOSAssetsForWallet(walletId, cursor = '', limit = 100) {
  try {
    if(walletId){
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}api/user/wallet/${walletId}/assets`
      );
    return response.data; 
    }
  } catch (error) {
    console.log("Encountered an error with GET API", error);
    return [];
  }
}


