import React from "react";

const NoResults = ({title, description}) => (
  <div>
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

export default NoResults;
